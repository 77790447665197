.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fancy {
  display: inline-block;
  background-color: #ff851b; /* Choose a suitable background color */
  color: #001f3f; /* Text color */
  padding: 10px 20px; /* Adjust padding for the desired size */
  margin: 1em;
  border: none;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold; /* Make the text bold */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.fancy:hover {
  background-color: #61dafb;
  color: #ff851b;
}
